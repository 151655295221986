.studentCourseDetailsBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 59.75rem;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 33.625rem;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .courseDetailsSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .appFeeDetailsSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 4rem;
  }
  
  .classList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 64rem;

    .leftContent {
      width: 37.875rem;
    }
    
    .courseDetailsSection {
      margin-top: 2rem;
    }
    
    .appFeeDetailsSection {
      margin-top: 2rem;
    }
    
  }

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;

    .leftContent {
      width: 100%;
    }

    .rightContent {
      margin-top: 2rem;
      margin-left: 0rem;
      width: 100%;
    }
    
    .courseDetailsSection {
      margin-top: 2rem;
    }
    
    .appFeeDetailsSection {
      margin-top: 2rem;
    }
    
  }
}
