.logEntryItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .selectSign {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: 0.25rem;
    height: unset;
    align-self: stretch;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.25rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .timeStampText {
    display: flex;
  }
  
  .logEntryStatus {
    display: flex;
    margin-top: 0.25rem;
  }
  
  .previewText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    border: 2px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
    .selectSign {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:hover {
    background-color: rgb(233, 255, 210);
    cursor: pointer; // MANUAL OVERRIDE
  
    .selectSign {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
    .selectSign {
      background-color: rgb(233, 255, 210);
    }
    
  }
}

.logEntryItemNew {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 243, 252);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .selectSign {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 243, 252);
    width: 0.25rem;
    height: unset;
    align-self: stretch;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.25rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .timeStampText {
    display: flex;
  }
  
  .logEntryStatus {
    display: flex;
    margin-top: 0.25rem;
  }
  
  .previewText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(243, 230, 239);
    border: 2px dashed rgb(93, 44, 78);
    outline-offset: 0;
  
    .selectSign {
      background-color: rgb(243, 230, 239);
    }
    
  }
  &:hover {
    background-color: rgb(243, 230, 239);
    cursor: pointer; // MANUAL OVERRIDE
  
    .selectSign {
      background-color: rgb(243, 230, 239);
    }
    
  }
  &:pressed {
    background-color: rgb(232, 208, 225);
  
    .selectSign {
      background-color: rgb(232, 208, 225);
    }
    
  }
}

.logEntryItemSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(233, 255, 210);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .selectSign {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(87, 125, 46);
    width: 0.25rem;
    height: unset;
    align-self: stretch;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.25rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .timeStampText {
    display: flex;
  }
  
  .logEntryStatus {
    display: flex;
    margin-top: 0.25rem;
  }
  
  .previewText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
