.imageOval {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 98.64661407470703px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 4.75rem;
  height: 4.75rem;

}

.imageSquare {
  overflow: hidden;
  display: block;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 4.5rem;
  height: 4.5rem;

}

// MANUAL OVERRIDE
.imageRectangle {
  overflow: hidden;
  display: block;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  width: 10rem;
  height: 3rem;
}