.courseRegistrationSubheader {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem 2rem 0rem 2rem;
  background-color: rgb(255, 255, 255);

  @media screen and (max-width: 720px) {
    padding: 2rem 0.5rem 0rem 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .textContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0rem 0rem 0.5rem 0rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .titleText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bodyText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .button {
    display: flex;
    
    @media screen and (max-width: 720px) {
      min-width: 60px;
    }
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
