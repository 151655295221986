.noSpotAvailablePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .genericStateNoActionBlock {
    display: flex;
    align-self: center;
    width: unset; // MANUAL OVERRIDE
    align-items: center;

    .image {
      display: flex;
      align-self: center;
    }

    .topText {
      display: flex;
      align-self: center;
    }

    .bottomText {
      display: flex;
      align-self: center;
    }

    .textValue {
      text-align: center;
    }
  }
}
