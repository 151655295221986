.createCourseScheduleBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .radioField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .classCardsList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .button {
    display: flex;
    margin-top: 1.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .backButton {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .createCourseButton {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

  }
}

// MANUAL OVERRRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
