.genericModal {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;
  width: 35rem;

  .topContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 2rem 0.5rem 2rem;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 2rem 0.5rem 2rem;
    width: unset;
    align-self: stretch;
  }
  
  .text1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem 2rem 2rem 2rem;
    width: unset;
    align-self: flex-start; //MANUAL OVERRIDE
  }
  
  .primaryButton {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .secondaryButton {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {

    .topContent {
      padding: 2rem 2rem 1rem 2rem;
    }
    
  }
}
