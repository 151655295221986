.initials20Amber {
  display: block;
  border-radius: 100px;
  background-color: rgb(243, 162, 58);
  width: 1.25rem;
  height: 1.25rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials20Aqua {
  display: block;
  border-radius: 100px;
  background-color: rgb(49, 169, 211);
  width: 1.25rem;
  height: 1.25rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials20Green {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 1.25rem;
  height: 1.25rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials20Purple {
  display: block;
  border-radius: 100px;
  background-color: rgb(179, 95, 154);
  width: 1.25rem;
  height: 1.25rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1440px) {
    background-color: rgb(141, 67, 119);

  }
}

.initials32Amber {
  display: block;
  border-radius: 100px;
  background-color: rgb(243, 162, 58);
  width: 2rem;
  height: 2rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials32Aqua {
  display: block;
  border-radius: 100px;
  background-color: rgb(49, 169, 211);
  width: 2rem;
  height: 2rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials32Green {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 2rem;
  height: 2rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials32Purple {
  display: block;
  border-radius: 100px;
  background-color: rgb(179, 95, 154);
  width: 2rem;
  height: 2rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1440px) {
    background-color: rgb(141, 67, 119);

  }
}

.initials48Amber {
  display: block;
  border-radius: 100px;
  background-color: rgb(243, 162, 58);
  width: 3rem;
  height: 3rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials48Aqua {
  display: block;
  border-radius: 100px;
  background-color: rgb(49, 169, 211);
  width: 3rem;
  height: 3rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials48Green {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 3rem;
  height: 3rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials48Purple {
  display: block;
  border-radius: 100px;
  background-color: rgb(179, 95, 154);
  width: 3rem;
  height: 3rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
  @media (min-width: 1440px) {
    background-color: rgb(141, 67, 119);

  }
}

.initials20Default {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 1.25rem;
  height: 1.25rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials32Default {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 2rem;
  height: 2rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}

.initials48Default {
  display: block;
  border-radius: 100px;
  background-color: rgb(124, 177, 65);
  width: 3rem;
  height: 3rem;

  .text {
    display: flex;
    flex-direction: row; // MANUAL OVERRIDE
    justify-content: center; // MANUAL OVERRIDE
    align-items: center; // MANUAL OVERRIDE
    height: 100%; // MANUAL OVERRIDE
  }
  
}
