.userProfileMedium {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .initials {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  
}

.userProfileSmall {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .initials {
    display: block;
    width: 2rem;
    height: 2rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}
