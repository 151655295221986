.logbookBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 25rem;
    height: unset;
    align-self: stretch;
  }
  
  .topSection {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
    flex: 0 0 auto;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .toggle {
    display: flex;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .logEntryStatusList {
    display: flex;
  }
  
  .divider1 {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .logEntriesCatalog {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomSection {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .divider2 {
    display: flex; // MANUAL OVERRIDE
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    overflow-y: auto;
    // MANUAL OVERRIDE
    //  total height - (main header + course header + reply section)
    height: calc(100vh - (12.75rem + 6.063rem));

    @media (min-width: 1440px) {
       // total height - (main header + course header + reply section)
       height: calc(100vh - (14.75rem + 6.063rem));
    }
  }
  
  .logEntry {
    display: flex;
    width: unset;
    align-self: stretch;
    flex: 0 0 auto; // MANUAL OVERRIDE
  }
  
  .textMessageList {
    display: flex;
    width: unset;
    align-self: stretch;
    flex-wrap: nowrap; // MANUAL OVERRIDE
  }
  
  .bottomContent1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }

  .replySection {
    display: flex;
    align-self: stretch;
  }

  .noLogSelectedState {
    display: flex;
    align-self: center;
    width: unset;
  }

  .rightContentEmptyState {
    background: #EFF4F4;
  }

  .logbookEntryMessagePreview {
    width: 20.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  // MANUAL OVERRIDE
  .labelText {
    flex: none;
    width: 11.25rem;
  }
}
