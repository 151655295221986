.textAreaFieldDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textArea {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}

.textAreaFieldError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textArea {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}

.textAreaFieldHelp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textArea {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}
