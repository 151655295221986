.teacherStatusTagCertified {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(233, 255, 210);

  .text {
    display: flex;
  }
  
}

.teacherStatusTagGraduate {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(240, 247, 255);

  .text {
    display: flex;
  }
  
}

.teacherStatusTagIn-Training {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(253, 236, 186);

  .text {
    display: flex;
  }
  
}

.teacherStatusTagUnderReview {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 100px;
  background-color: rgb(255, 243, 252);

  .text {
    display: flex;
  }
  
}
