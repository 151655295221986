.studentUpdatedPasswordPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .genericStateNoActionBlock {
    display: flex;
    width: 41rem;

    .topText {
      display: flex;
      align-self: center;
    }

    .bottomText {
      display: flex;
      align-self: center;
    }
  }
  
  @media (min-width: 1440px) {

    .genericStateNoActionBlock {
      width: 50rem;
    }
    
  }
}
