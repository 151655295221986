.studentKoruAccountPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .studentKoruAccountBlock {
    display: flex;
    width: 41rem;
  }
  
  @media (min-width: 1440px) {

    .studentKoruAccountBlock {
      width: 50rem;
    }
    
  }
}

// MANUAL OVERRIDE
.loadingSpinner {
  width: 100%;
}

// MANUAL OVERRIDE
.loadingContainer {
  height: 100%;
}

// MANUAL OVERRIDE
.courseRegistrationSubheader {
  display: flex;
  align-self: stretch;
  width: 100%;
}
