.selectError {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);
  outline: 2px solid rgb(195, 63, 56);
  outline-offset: 0;
  width: 100%;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.625rem;
  }
  
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}

.selectNone {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);
  width: 100%;

  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.625rem;
  }
  
  &:focus-visible {
    outline: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:selected {
    outline: 2px solid rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:focus-visible {
    outline: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:selected {
    outline: 2px solid rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  }
}
