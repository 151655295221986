.buttonContainedLargeIconTextPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1rem;
  border-radius: 8px;
  background-color: rgb(53, 120, 189);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1.25rem 0.625rem 1rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  }
}

.buttonContainedLargeTextCriticalDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(195, 63, 56);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(183, 56, 49);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(183, 56, 49);
  
  }
  &:pressed {
    background-color: rgb(168, 14, 4);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(183, 56, 49);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(183, 56, 49);
  
  }
  &:pressed {
    background-color: rgb(168, 14, 4);
  
  }
  }
}

.buttonContainedLargeTextPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(53, 120, 189);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  }
}

.buttonContainedLargeTextIconPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(53, 120, 189);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1rem 0.625rem 1.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:disabled {
    background-color: rgb(200, 204, 204);
  
  }
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 1px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
  
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
  }
}

.buttonContainedLargeTextIconPrimaryActionLoading {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(200, 204, 204);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 0.625rem 1rem 0.625rem 1.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}

.buttonContainedLargeTextPrimaryActionLoading {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(200, 204, 204);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 0.625rem 1rem 0.625rem 1.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.buttonContainedLargeTextIconCriticalLoading {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(200, 204, 204);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 0.625rem 1rem 0.625rem 1.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}

.buttonContainedLargeTextCriticalLoading {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1rem 0.75rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(200, 204, 204);

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 0.625rem 1rem 0.625rem 1.25rem;

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}

.buttonOutlineLargeTextCriticalDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 8px;
  outline: 2px solid rgb(195, 63, 56);
  outline-offset: 0;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  &:disabled {
    outline: 2px solid rgb(222, 225, 225);
  
  }
  &:focus-visible {
    background-color: rgb(247, 207, 211);
    outline: 2px dashed rgb(195, 63, 56);
  
  }
  &:hover {
    background-color: rgb(247, 207, 211);
  
  }
  &:pressed {
    background-color: rgb(227, 158, 156);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  &:disabled {
    outline: 2px solid rgb(222, 225, 225);
  
  }
  &:focus-visible {
    background-color: rgb(247, 207, 211);
    outline: 2px dashed rgb(195, 63, 56);
  
  }
  &:hover {
    background-color: rgb(247, 207, 211);
  
  }
  &:pressed {
    background-color: rgb(227, 158, 156);
  
  }
  }
}

.buttonOutlineLargeTextPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  border-radius: 8px;
  outline: 2px solid rgb(53, 120, 189);
  outline-offset: 0;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(255, 255, 255);   // MANUAL OVERRIDE
    outline: 2px solid rgb(222, 225, 225);
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {
    padding: 0.625rem 1.25rem 0.625rem 1.25rem;

  &:disabled {
    outline: 2px solid rgb(222, 225, 225);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.buttonTextSmallIconBaseDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.buttonTextSmallIconPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.buttonTextSmallIconTextPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.buttonTextSmallTextPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}

.buttonTextSmallTextIconBaseDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.buttonTextSmallTextIconPrimaryActionDefault {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  .content {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {

    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  &:disabled {
    background-color: rgb(255, 255, 255);
  
  }
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

// MANUAL OVERRIDE
.loader {
  margin-left: 0.5rem;
  border: 0.25rem solid #C8CCCC;
  border-top: 0.25rem solid rgb(255, 255, 255);
  border-radius: 50%;
  width: 0.75rem;
  height: 0.75rem;
  animation: spin 2s linear infinite;
}

// MANUAL OVERRIDE
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}