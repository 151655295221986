.paymentHistorySection {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .header {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .tableHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 34rem;
    margin-top: 1rem;
  }
  
  .dateText {
    display: flex;
    width: 6rem;
  }
  
  .amountText {
    display: flex;
    width: 6.75rem;
    margin-left: 1rem;
  }
  
  .productNameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .linkText {
    display: flex;
    width: 5.5rem;
    margin-left: 1rem;
  }
  
  .productList {
    display: flex;
    width: 34rem;
    margin-top: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 2rem 2rem 2rem;

    .tableHeader {
      width: 47.8125rem;
    }
    
    .dateText {
      width: 7rem;
    }
    
    .amountText {
      width: 7.75rem;
    }
    
    .linkText {
      width: 7rem;
    }
    
    .productList {
      width: 47.8125rem;
    }
    
  }
}
