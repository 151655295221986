.koruLogo {
  overflow: hidden;
  display: block;
  width: 5.625rem;
  height: 2.375rem;

  path[id=ContentThree], [id=ContentThree] path {
    display: block;
    fill: rgb(127, 180, 57);
  }
  
  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(0, 59, 81);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(51, 120, 193);
  }
  
}
