.classCardsList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1.5rem;

  .classFormCard {
    display: flex;
    width: 100%;
    align-self: stretch;
  }
  
}
