.actionSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(240, 247, 255);

  .textGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topText {
    display: flex;
    width: unset;
    align-self: center;
    margin: auto;
  }
  
  .bottomText {
    display: flex;
    width: unset;
    align-self: center;
    margin-top: 0.5rem;
  }
  
  .button {
    display: flex;
    margin-top: 1rem;
  }
  
}
