.checkboxItemChecked {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}

.checkboxItemUnchecked {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}

.checkboxItemDisabled {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);

  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.75rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}