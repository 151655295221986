.backDrop {
  position: fixed;
  z-index: 999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
}

.modalWrapper {
  position: fixed;
  z-index: 999;
  border: 1px solid #EBECEF;
  border-radius: 8px;
  background-color: white;
}
