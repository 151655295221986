.appFeeDetailsSection {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .header {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .decriptionText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .iconTextGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .labelIcon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .infoText {
    display: flex;
    width: unset;
    flex: 1;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 2rem 2rem 2rem 2rem;

    .labelIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}
