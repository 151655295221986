.studentListSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .header {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .studentsList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
