.coursesHeader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 2rem 0rem 2rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .tabsList {
    display: flex;
    margin-top: 1rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
