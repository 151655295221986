.courseCardsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.5rem;

  .courseCard {
    display: flex;
    width: calc(50% - 0.75rem);
    height: 32.4375rem;
  }
  
  @media (min-width: 1440px) {

    .courseCard {
      width: calc(33.333333333333336% - 1rem);
    }
    
  }
}
