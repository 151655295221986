.accountExpiredPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .genericStateBlock {
    display: flex;
    width: 41rem;
  }

  .fontVariant {
    font-family: 'Bitter', 'sans-serif';
    font-weight: 500;
  }

  .link { // MANUAL OVERRIDE
    text-decoration: none;
    color: #276EF1;
  }
  
  @media (min-width: 1440px) {

    .genericStateBlock {
      width: 50rem;
    }
    
  }
}
