.productItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 34rem;

  .dateText {
    display: flex;
    width: 6rem;
  }
  
  .amountText {
    display: flex;
    width: 6.75rem;
    margin-left: 1rem;
  }
  
  .productNameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .button {
    display: flex;
    margin-left: 1rem;
  }
  
  @media (min-width: 1440px) {
    width: 47.8125rem;

    .dateText {
      width: 7rem;
    }
    
    .amountText {
      width: 7.75rem;
    }
    
  }
}
