.createCourseSchedulePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .createCourseHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    overflow: scroll;     // MANUAL OVERRIDE
  }
  
  .createCourseScheduleBlock {
    display: flex;
    width: 41rem;
  }
  
  @media (min-width: 1440px) {

    .createCourseScheduleBlock {
      width: 50rem;
    }
    
  }
}

// MANUAL OVERRRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
