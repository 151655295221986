.iconTextGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .labelIcon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .infoText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  @media (min-width: 1440px) {

    .labelIcon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
  }
}
