.headerSidenavLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .header1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    height: 100%;     // MANUAL OVERRIDE
  }
  
  .myProfilePage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }

  .coursesPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }

  .archivedCoursesPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }

  .createCourseBasicInfoPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }

  .courseDetailsPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }
  
  .createCourseSchedulePage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 3.25rem;     // MANUAL OVERRIDE
  }
}