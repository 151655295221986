.textMessageCoteacherLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .textBubble {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    border-radius: 12px 12px 0px 12px;
    background-color: rgb(253, 236, 186);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15000000596046448);
  }
  
  .text {
    display: flex;
  }
  
  .textBubbleTag {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.5rem;
  }
  
  .initials {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text1 {
    display: flex;
    margin-left: 0.5rem;
  }
  
}

.textMessageStudentRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;

  .textBubble {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    border-radius: 12px 12px 12px 0px;
    background-color: rgb(240, 247, 255);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15000000596046448);
  }
  
  .text {
    display: flex;
  }
  
  .textBubbleTag {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.5rem;
  }
  
  .initials {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text1 {
    display: flex;
    margin-left: 0.5rem;
  }
  
}

.textMessageTeacherLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .textBubble {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
    border-radius: 12px 12px 0px 12px;
    background-color: rgb(233, 255, 210);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15000000596046448);
  }
  
  .text {
    display: flex;
  }
  
  .textBubbleTag {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0.5rem;
  }
  
  .initials {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text1 {
    display: flex;
    margin-left: 0.5rem;
  }
  
}
