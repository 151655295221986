@import './resources/scss/fonts.scss';

$headings-margin-bottom: 0;
$paragraph-margin-bottom: 0;
$line-height-base: 1;

// Import reset.css to override some of the bootstrap global settings
@import './reset.css';

.layout {
  height: 100%;
}

.notificationContainer {
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}