.address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .streetAddressInputField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .selectFieldGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .inputFieldGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  // MANUAL OVERRIDE
  .contextualMenuList {
    flex-wrap: nowrap;
    overflow: auto;
    max-height: 12rem;
  }
}
