.rosterBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 56.25rem;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 37rem;
  }
  
  .enrolledStudentsSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .waitlistSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .removedStudentsSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1.5rem;
  }
  
  .reminderEmailsSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
// MANUAL OVERRIDE
  .copyStudentsEmailSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 78.75rem;

    .leftContent {
      width: 51.8125rem;
    }
    
    .waitlistSection {
      margin-top: 2rem;
    }
    
    .removedStudentsSection {
      margin-top: 2rem;
    }
    
    .rightContent {
      margin-left: 2rem;
    }
    
  }
}

.requirementsList {
  list-style: inside;
  margin: 0rem 0rem 0rem 0.5rem;
}

.contextualMenu { // MANUAL OVERRIDE
  width: 16.688rem;
  z-index: 2;
}

// MANUAL OVERRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}