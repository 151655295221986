.tuitionPaymentBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .inlineNotification {
    display: flex;
    align-self: stretch;
    margin-bottom: 1rem;
  }
  
  .priceItemList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .discountCode {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .discountCodeButton {
    display: flex;
    align-self: center;
  }

  .priceListItem {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .cardInfoField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .radioField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .address {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  // MANUAL OVERRIDE
  .contextualMenuList {
    flex-wrap: nowrap;
    overflow: auto;
    max-height: 15rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

  }
}
