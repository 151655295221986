.textAreaErrorL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);
  outline: 2px solid rgb(195, 63, 56);
  outline-offset: 0;
  align-self: stretch;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    align-self: stretch;
    font-family: sans-serif; // MANUAL OVERRIDE
    line-height: 1.25rem; // MANUAL OVERRIDE
    padding: 0; // MANUAL OVERRIDE
    font-size: 0.875rem; // MANUAL OVERRIDE
    @media (min-width: 1440px) { // MANUAL OVERRIDE
      line-height: 1.5rem;
      font-size: 1rem; // MANUAL OVERRIDE
    }
  }
  
}

.textAreaNoneL {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);
  align-self: stretch;

  .input {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    align-self: stretch;
    font-family: sans-serif; // MANUAL OVERRIDE
    line-height: 1.25rem; // MANUAL OVERRIDE
    padding: 0; // MANUAL OVERRIDE
    font-size: 0.875rem; // MANUAL OVERRIDE
    @media (min-width: 1440px) { // MANUAL OVERRIDE
      line-height: 1.5rem;
      font-size: 1rem; // MANUAL OVERRIDE
    }
    
    // MANUAL OVERRIDE
    &:disabled {
      color: rgb(146, 154, 155);
      -webkit-text-fill-color: rgb(146, 154, 155);
    }
  }
  
  &:error {
    outline: 2px solid rgb(195, 63, 56);
    outline-offset: 0;
  
  }
  &:focus-visible {
    outline: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    outline: 2px solid rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:focus-within {
    outline: 2px solid rgb(53, 120, 189);
    outline-offset: 0;
  }
}
