.detailsBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 56.25rem;

  .labelText {
    flex: none;
    width: 8.5rem;
  }

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 37rem;
  }
  
  .detailsBasicInfoSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .detailsClassInfoSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1;
    margin-left: 1.5rem;
  }
  
  .registrationStatusSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .copyLinkSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    width: 78.75rem;

    .leftContent {
      width: 51.8125rem;
    }
    
    .detailsClassInfoSection {
      margin-top: 2rem;
    }
    
    .rightContent {
      margin-left: 2rem;
    }
    
    .copyLinkSection {
      margin-top: 2rem;
    }
    
  }
}

.link { // MANUAL OVERRIDE
  text-decoration: none;
  color: #276EF1;
}

// MANUAL OVERRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}