.toggleItemSelected {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(53, 120, 189);

  .text {
    display: flex;
  }
  
  &:focus-visible {
    background-color: rgb(37, 85, 133);
    outline: 2px dashed rgb(255, 255, 255);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(37, 85, 133);
    cursor: pointer; // MANUAL OVERRIDE
  }
  &:pressed {
    background-color: rgb(22, 49, 77);
  
  }
}

.toggleItemUnselected {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);

  .text {
    display: flex;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
    cursor: pointer; // MANUAL OVERRIDE
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}
