.signInLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .header1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: center;
    flex: 1 1 auto;

    @media screen and (max-width: 720px) {
      width: 100%;
    }
  }
  
  .signinPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
}