.inputFieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .inputFieldOne {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .inputFieldTwo {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
}
