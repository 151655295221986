.passwordLinkExpiredPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .genericActionBlock {
    display: flex;
    width: 41rem;
  }
  
  @media (min-width: 1440px) {

    .genericActionBlock {
      width: 50rem;
    }
    
  }
}
