.studentCourseDetailsFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1.5rem 1rem 1.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 1rem;
  }
  
  @media (min-width: 1440px) {

    .content {
      padding: 1.5rem 2rem 1.5rem 2rem;
    }
    
  }
}
