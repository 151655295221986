.navMenuItemIconDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    border: 2px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:hover {
    background-color: rgb(233, 255, 210);
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
    .indicator {
      background-color: rgb(223, 249, 194);
    }
    
  }
}

.navMenuItemIconSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(233, 255, 210);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(87, 125, 46);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
}

.navMenuItemIconTextDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: 9.5rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    border: 2px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
    .text {
      width: unset;
      flex: 1 1 auto;
    }
    
  }
  &:hover {
    background-color: rgb(233, 255, 210);
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
    .text {
      width: unset;
      flex: 1 1 auto;
    }
    
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
    .indicator {
      background-color: rgb(223, 249, 194);
    }
    
    .text {
      width: unset;
      flex: 1 1 auto;
    }
    
  }
  @media (min-width: 1440px) {

    .text {
      width: unset;
      flex: 1 1 auto;
    }
    
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    border: 2px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:hover {
    background-color: rgb(233, 255, 210);
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
    .indicator {
      background-color: rgb(223, 249, 194);
    }
    
  }
  }
}

.navMenuItemIconTextSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(233, 255, 210);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(87, 125, 46);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.navMenuItemIconTextIconDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(255, 255, 255);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .trailingIcon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    border: 2px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:hover {
    background-color: rgb(233, 255, 210);
  
    .indicator {
      background-color: rgb(233, 255, 210);
    }
    
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
    .indicator {
      background-color: rgb(223, 249, 194);
    }
    
  }
}

.navMenuItemIconTextIconSelected {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(233, 255, 210);

  .indicator {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(87, 125, 46);
    width: 0.125rem;
    height: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
  .trailingIcon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
  }
  
}
