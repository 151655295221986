.chipDefaultMTextNoneDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 20px;
  background-color: rgb(217, 219, 222);
  width: 5rem;

  .text {
    display: flex;
  }
  
  @media (min-width: 1440px) {
    padding: 0.25rem 1rem 0.25rem 1rem;
    width: 6.25rem;
  }
}

.chipOutlineWhiteLTextIconNoneDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(217, 219, 222);
  outline-offset: 0;

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }
  
  &:focus-visible {
    outline: 1px dashed rgb(4, 95, 216);
  
  }
  @media (min-width: 1440px) {

    .chipContent {
      width: unset;
      align-self: stretch;
    }
    
    &:focus-visible {
      outline: 1px dashed rgb(4, 95, 216);
    }
  }
}

.chipWhiteLTextIconErrorDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 20px;
  background-color: rgb(255, 230, 231);

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.625rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }
  
  &:focus-visible {
    outline: 1px dashed rgb(0, 0, 0);
    outline-offset: 0;
  
  }
  @media (min-width: 1440px) {

    .chipContent {
      width: unset;
      align-self: stretch;
    }
    
    .icon {
      width: 1.5rem;
      height: 1.5rem;
    }
    
    .text {
      margin-left: 0.5rem;
    }
    
    .button {
      margin-left: 0.5rem;
    }
    
    &:focus-visible {
      outline: 1px dashed rgb(0, 0, 0);
      outline-offset: 0;
    
    }
  }
}

.chipWhiteLTextIconNoneDefault {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 20px;
  background-color: rgb(255, 255, 255);

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(0, 0, 0);
    outline-offset: 0;
  
  }
  
  @media (min-width: 1440px) {

    .chipContent {
      width: unset;
      align-self: stretch;
    }
    
  }
}

.chipOutlineWhiteLTextIconNoneEdit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .input {
    font-size: 0.875rem;
    display: flex;

    @media (min-width: 1440px) { // MANUAL OVERRIDE
      font-size: 1rem;
    }
  }
  
}

.chipWhiteLTextIconNoneEdit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    color: red;
  }
  
  .input {
    font-size: 0.875rem;
    display: flex;

    @media (min-width: 1440px) { // MANUAL OVERRIDE
      font-size: 1rem;
    }
  }
  
}

.chipWhiteLTextIconErrorEdit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    padding: 0.5rem;
  }
  
  .input {
    font-size: 0.875rem;
    display: flex;

    @media (min-width: 1440px) { // MANUAL OVERRIDE
      font-size: 1rem;
    }
  }
}

.sizingSpan {
  opacity: 0;
  position: absolute;
  font-family: Inter, sans-serif;

  font-size: 0.875rem;
  display: flex;

  @media (min-width: 1440px) {
    font-size: 1rem;
  }
}

.input {
  font-family: Inter, sans-serif;
}

.chipWhiteLTextIconNoneBlocked {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  pointer-events: none;


  .chipContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 0.8;
  }

  .text {
    display: flex;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }

  &:focus-visible {
    outline: 1px dashed rgb(0, 0, 0);
    outline-offset: 0;
  
  }
  
  @media (min-width: 1440px) {

    .chipContent {
      width: unset;
      align-self: stretch;
    }
    
  }
}
