.classFormCardBoth {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .topContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.75rem;
  }
  
  .textFieldGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .dateInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .startTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .endTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .locationInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .zoomLinkInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}

.classFormCardInPerson {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .topContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.75rem;
  }
  
  .textFieldGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .dateInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .startTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .endTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .locationInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}

.classFormCardOnline {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .topContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.75rem;
  }
  
  .textFieldGroup {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .dateInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .startTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .endTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .zoomLinkInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}

.classFormCardtype3 {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;

  .topContent {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 0.75rem;
  }
  
  .textFieldGroup {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .dateInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .startTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .endTimeInputField {
    display: flex;
    width: calc(33.333333333333336% - 0.83333333333rem);
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
    margin-left: 1.25rem;
  }
  
  .locationInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
  .zoomLinkInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.75rem;
  }
  
}
