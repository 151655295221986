.studentItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 1rem 0rem;
    width: unset;
    align-self: stretch;

    & svg path { // MANUAL OVERRIDE
      fill: #276EF1;
    }
  }
  
  .initials {
    display: block;
    width: 3rem;
    height: 3rem;
  }
  
  .studentInfo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1rem;
  }
  
  .nameText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .infoText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}
