.leftNav {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  // total height - (main header)
  height: calc(100vh - 3.5625rem);
  width: 3.25rem;     // MANUAL OVERRIDE
  position: absolute;     // MANUAL OVERRIDE
  overflow: hidden;     // MANUAL OVERRIDE
  transition: width 0.15s ease-out;     // MANUAL OVERRIDE
  z-index: 999;     // MANUAL OVERRIDE

  .navMenuList {
    display: flex;
  }
  
  .divider {
    display: block;
    width: 0.0625rem;
    height: unset;
    align-self: stretch;
  }
  
  &:hover {
    width: 15rem;     // MANUAL OVERRIDE

    .navMenuList {
      width: 19.9375rem;
    }
    
  }
  @media (min-width: 1440px) {
    // total height - (main header)
    height: calc(100vh - 3.5625rem);

  &:hover {
  
    .navMenuList {
      width: unset;
      flex: 1 1 auto;
    }
    
  }
  }
}
