.studentSignupBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  @media screen and (max-width: 720px) {
    padding: 1.5rem 0.5rem 1.5rem 0.5rem;
    width: 100%;
  }

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .inputFieldGroup {
    display: flex;
    width: auto;
    align-self: stretch;

    @media screen and (max-width: 720px) {
      flex-direction: column;
      width: 100%;
    }
  }

  .lastName {
    align-self: stretch;
    display: flex;
    flex: 1 1 auto;
    height: auto;
    width: auto;
    margin-left: 1rem;
    
    @media screen and (max-width: 720px) {
      margin: 0.5rem 0 0;
      width: 100%;
    }
  }
  
  .emailInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .phoneNumberInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .passwordInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .confirmPasswordInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .checkboxItem {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .legalText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

  }
}
