.selectFieldGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .selectFieldOne {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1; // MANUAL OVERRIDE
  }
  
  .selectFieldTwo {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1; // MANUAL OVERRIDE
    margin-left: 1rem;
  }
  
  @media (min-width: 1440px) {

    .selectFieldTwo {
      height: unset;
    }
    
  }
}
