.headerDefault {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.8125rem 0.75rem 0.8125rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .koruLogo {
    display: block;
    width: 5.625rem;
    height: 2.375rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {

    .content {
      padding: 0.875rem 0.75rem 0.8125rem 0.75rem;
    }
    
  }
}

.headerSearch {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.0625rem 0.75rem 1rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    flex: 1 1 auto;
  }
  
  .text {
    display: flex;
    width: 59rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.625rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {

    .content {
      padding: 1rem 0.75rem 1rem 0.75rem;
    }
    
    .text {
      width: 85rem;
    }
    
  }
}

.headerSearchIcon {
  // MANUAL OVERRIDE - removed overflow: hidden
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .koruLogo {
    display: block;
    width: 5.625rem;
    height: 2.375rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.625rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .userProfile {
    display: flex;
    margin-left: 0.75rem;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
}

.headerWithUserProfile {
  // MANUAL OVERRIDE - removed overflow: hidden
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    width: unset;
    align-self: stretch;
  }
  
  .leftContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  
  .koruLogo {
    display: block;
    width: 5.625rem;
    height: 2.375rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 0.625rem;
  }
  
  .userProfile {
    display: flex;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .contextualMenu { // MANUAL OVERRIDE
    width: 16.688rem;
  }

  .contextualMenuHidden { // MANUAL OVERRIDE
    display: none;
  }
}


.contextualMenu { // MANUAL OVERRIDE
  width: 16.688rem;
}
