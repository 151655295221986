.textIconGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .infoText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .labelIcon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 1px dashed rgb(53, 120, 189);
    outline-offset: 0;
  }

  &:hover {
    background-color: rgb(240, 247, 255);
  }
  
  &:pressed {
    background-color: rgb(216, 235, 255);
  }
}
