.selectFieldDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .select {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  // MANUAL OVERRIDE
  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}

.selectFieldError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .select {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  // MANUAL OVERRIDE
  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
}

// MANUAL OVERRIDE
.selectFieldNoOptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .select {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .textInput {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}