.welcomeBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin: auto;
  }
  
  .textGroup {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .leftText {
    display: flex;
  }
  
  .rightText {
    display: flex;
    margin-left: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }

  .staffItem {
    display: flex;
    width: unset;
    flex: 1;
  }

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .bodyText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .noteText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin: 1rem auto auto auto;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

    .divider {
      margin-top: 1rem;
    }
    
    .row {
      margin-top: 1rem;
    }
    
  }
}
