.tabSelected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 2px 2px 0px 0px;
  background-color: rgb(233, 255, 210);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  }
  
  .text {
    display: flex;
  }
  
  .div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(87, 125, 46);
    width: unset;
    height: 0.125rem;
    align-self: stretch;
  }
  
  &:disabled {
    background-color: rgb(239, 244, 244);
  
    .div {
      background-color: rgb(222, 225, 225);
    }
    
  }
  &:focus-visible {
    background-color: rgb(223, 249, 194);
    outline: 1px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(223, 249, 194);
  
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
  }
  @media (min-width: 1440px) {

    .content {
      padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    }
    
  &:disabled {
    background-color: rgb(239, 244, 244);
  
    .div {
      background-color: rgb(222, 225, 225);
    }
    
  }
  &:focus-visible {
    background-color: rgb(223, 249, 194);
    outline: 1px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(223, 249, 194);
  
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
  }
  }
}

.tabUnselected {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.75rem 0.5rem 0.75rem;
  }
  
  .text {
    display: flex;
  }
  
  .div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 0.625rem 0 0.625rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    height: 0.125rem;
    align-self: stretch;
  }
  
  &:disabled {
    border-radius: 2px 2px 0px 0px;
  
  }
  &:focus-visible {
    border-radius: 2px 2px 0px 0px;
    background-color: rgb(233, 255, 210);
    outline: 1px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
  }
  &:hover {
    border-radius: 2px 2px 0px 0px;
    background-color: rgb(233, 255, 210);
  
  }
  &:pressed {
    border-radius: 2px 2px 0px 0px;
    background-color: rgb(223, 249, 194);
  
  }
  @media (min-width: 1440px) {
    border-radius: 2px 2px 0px 0px;

    .content {
      padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    }
    
  &:focus-visible {
    background-color: rgb(233, 255, 210);
    outline: 1px dashed rgb(87, 125, 46);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(233, 255, 210);
  
  }
  &:pressed {
    background-color: rgb(223, 249, 194);
  
  }
  }
}
