.textBoldCenterBrandBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(87, 125, 46);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterBrandBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(87, 125, 46);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textBoldCenterCriticalBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textBoldCenterDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textBoldCenterDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textBoldCenterSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textBoldLeftDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textBoldLeftDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textBoldLeftNewBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(93, 44, 78);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftNewBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(93, 44, 78);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textBoldLeftSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textBoldRightDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textBoldRightDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textBoldRightSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularCenterBrandBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(87, 125, 46);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterCriticalLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterDefaultLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterDefaultLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterDisabledLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterDisabledLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterInfoLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(53, 120, 189);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterInverseBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterInverseLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularCenterSubduedLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularCenterSubduedLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textRegularLeftCriticalBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftCriticalBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftCriticalLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftDefaultLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftDefaultLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftDisabledLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftDisabledLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftInfoBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(53, 120, 189);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftInfoLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(53, 120, 189);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftInverseBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftInverseLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularLeftSubduedLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularLeftSubduedLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textRegularRightCriticalLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(195, 63, 56);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightDefaultBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightDefaultLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightDefaultLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightDisabledBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightDisabledLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightDisabledLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightInfoBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(53, 120, 189);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightInfoLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(53, 120, 189);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightInverseBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightInverseLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightSubduedBody1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textRegularRightSubduedLabel1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.125rem;
      line-height: 1.5rem;
    }
    
  }
}

.textRegularRightSubduedLabel2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textSemiboldCenterDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textSemiboldCenterDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textSemiboldCenterInverseBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textSemiboldCenterSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: center;
  }
  
}

.textSemiboldLeftDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textSemiboldLeftDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textSemiboldLeftInverseBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textSemiboldLeftSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: left;
  }
  
}

.textSemiboldRightDefaultBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textSemiboldRightDisabledBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textSemiboldRightSubduedBody2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Cabin', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25rem;
    text-align: right;
  }
  
}

.textBoldCenterDefaultHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldCenterDisabledHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldCenterSubduedHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldLeftBrandHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(87, 125, 46);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldLeftDefaultHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldLeftDisabledHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldLeftSubduedHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldRightDefaultHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldRightDisabledHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldRightSubduedHeading1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2.5rem;
      line-height: 3rem;
    }
    
  }
}

.textBoldCenterDefaultHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldCenterDisabledHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldCenterSubduedHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldLeftDefaultHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldLeftDisabledHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldLeftInverseHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(255, 255, 255);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldLeftSubduedHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldRightDefaultHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldRightDisabledHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldRightSubduedHeading2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    
  }
}

.textBoldCenterDefaultHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldCenterDisabledHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldCenterSubduedHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldLeftDefaultHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldLeftDisabledHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldLeftSubduedHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldRightDefaultHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldRightDisabledHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldRightSubduedHeading3 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.5rem;
    }
    
  }
}

.textBoldCenterDefaultHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: center; // MANUAL OVERRIDE
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterDisabledHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldCenterSubduedHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftDefaultHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftDisabledHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldLeftSubduedHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: left;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightDefaultHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(81, 94, 95);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightDisabledHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(200, 204, 204);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}

.textBoldRightSubduedHeading4 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .value {
    display: block;
    color: rgb(146, 154, 155);
    font-family: 'Bitter', 'sans-serif';
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: right;
  }
  
  @media (min-width: 1440px) {

    .value {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    
  }
}
