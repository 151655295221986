.myProfileBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 56.25rem;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 37rem;
  }
  
  .dashboardInfoSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .basicInfoSection {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1;
    margin-left: 1.5rem;
  }
  
  .teacherDirectorySection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 78.75rem;

    .leftContent {
      width: 51.8125rem;
    }
    
    .basicInfoSection {
      margin-top: 2rem;
    }
    
    .rightContent {
      margin-left: 2rem;
    }
    
  }
}

.link {
  text-decoration: none;
  color: #276EF1;
}