.headerOnlyLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .header1 {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .signupPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .expired { // MANUAL OVERRIDE
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .congratulations { // MANUAL OVERRIDE
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .studentKoruAccountPage { // MANUAL OVERRIDE
    margin-left: auto;
    margin-right: auto;
  }

  .registrationSuccess { // MANUAL OVERRIDE
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }

  .onWaitlist {  // MANUAL OVERRIDE
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
}
