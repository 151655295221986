.congratulationsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .genericStateTwoBlock {
    display: flex;
    width: 41rem;
  }
  
  @media (min-width: 1440px) {

    .genericStateTwoBlock {
      width: 50rem;
    }
  }

  .link { // MANUAL OVERRIDE
    text-decoration: none;
    color: #276EF1;
  }

  .skipLink { // MANUAL OVERRIDE
    background: transparent;
    cursor: pointer;
    margin-top: 2.125rem;

    &:hover {
      background: transparent;
    }
  }
}
