.textMessageList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  gap: 1rem;

  .textMessage {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    gap: 1.5rem;

  }
}
