.archivedCoursesPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .coursesHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    width: unset;
    align-self: stretch;
    overflow: scroll;     // MANUAL OVERRIDE
  }
  
  .coursesBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .archivedCourseCardTopContent {
    background: #515E5F;;
  }
  
}
