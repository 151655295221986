.genericStateTwoBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .image {
    display: block;
    width: 32rem;
    height: 16rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .textGroupTop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topText {
    display: flex;
    width: 100%;
    align-self: stretch;

    & h3 {
      width: 100%;
    }
  }
  
  .bottomText {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;

    & p {
      width: 100%;
    }
  }
  
  .textGroupBottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .topText1 {
    display: flex;
    width: 100%;
    align-self: stretch;

    & h4 {
      width: 100%;
    }
  }
  
  .bottomText1 {
    display: flex;
    width: 100%;
    align-self: stretch;
    margin-top: 0.5rem;

    & p {
      width: 100%;
    }
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .button1 {
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

    .image {
      width: 41rem;
      height: 20.5rem;
    }
    
  }
}
