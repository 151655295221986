.logEntryStatusList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0.5rem;

  .logEntryStatusItem {
    display: flex;
  }
  
  @media (min-width: 1440px) {
    gap: 0.75rem;

  }
}
