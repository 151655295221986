.registrationStatusTagClosed {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  border-radius: 24px;
  background-color: rgb(255, 255, 255);

  .text {
    display: flex;
  }
  
}

.registrationStatusTagOpen {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
  border-radius: 24px;
  background-color: rgb(255, 255, 255);

  .text {
    display: flex;
  }
  
}
