$primary: rgb(53, 120, 189);

.loadingSpinnerSmall {
  width: 1.25rem;
  height: 1.25rem;
}

.loadingSpinnerLarge {
  width: 4rem;
  height: 4rem;
}

.loadingSpinnerHuge {
  width: 10rem;
  height: 10rem;
}

.loadingSpinnerPrimary {
  .spinner {
    .path {
      stroke: $primary;
    }
  }
}

.loadingSpinnerBlack {
  .spinner {
    .path {
      stroke: black;
    }
  }
}

.loadingSpinnerWhite {
  .spinner {
    .path {
      stroke: white;
    }
  }
}
.loadingSpinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  row-gap: 1.25rem;
}
.loadingText {
  letter-spacing: 0.05em;
}
.loadingSpinner {
  .spinner {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    z-index: 2;
    width: 100%;
    height: 100%;

    .path {
      stroke-linecap: round;
      -webkit-animation: dash 1.5s ease-in-out infinite;
      animation: dash 1.5s ease-in-out infinite;
    }
  }

  @-webkit-keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }

}
