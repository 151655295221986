.inlineNotificationError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 8px;
  background-color: rgb(252, 236, 238);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 1rem 1rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.625rem;
  }
  
}
