.logEntriesAccordianItemCollapsedDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    border: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}

.logEntriesAccordianItemCollapsedNew {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 243, 252);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(243, 230, 239);
    border: 2px dashed rgb(93, 44, 78);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(243, 230, 239);
  
  }
  &:pressed {
    background-color: rgb(232, 208, 225);
  
  }
}

.logEntriesAccordianItemExpandedDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .nameText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .logEntriesList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
  
    .content {
      background-color: rgb(240, 247, 255);
      outline: 2px dashed rgb(53, 120, 189);
      outline-offset: 0;
    }
    
  }
  &:hover {
  
    .content {
      background-color: rgb(240, 247, 255);
    }
    
  }
  &:pressed {
  
    .content {
      background-color: rgb(216, 235, 255);
    }
    
  }
}
