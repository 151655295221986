.discountCodeApplied {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .inputField {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 1rem;
  }
  
  .priceListItem {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}

.discountCodeDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .button {
    display: flex;
  }
  
}

.discountCodeExpanded {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  .topContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    background-color: rgb(255, 255, 255);
    width: unset;
    flex: 1 1 auto;
  }
  
  .inputField {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 1rem;
  }
  
}
