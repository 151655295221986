.coursesBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 2rem 2rem 2rem 2rem;

  .courseCardsList {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
}
