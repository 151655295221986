.toastNotificationCritical {
  display: flex;
  width: 10rem; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(0, 0, 0, 0.800000011920929);

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.625rem;
  }
  
}

.toastNotificationInfo {
  display: flex;
  width: 10rem; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(0, 0, 0, 0.800000011920929);

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.625rem;
  }
  
}

.toastNotificationSuccess {
  display: flex;
  min-width: 10rem; // MANUAL OVERRIDE
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 0px 0px 8px 8px;
  background-color: rgba(0, 0, 0, 0.800000011920929);

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0.625rem 0.625rem 0.625rem 0.625rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.625rem;
  }
  
}
