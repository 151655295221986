.createCourseBasicDetailsBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .courseNameInputField {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .radioField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }

  .radioItem {
    align-items: flex-start;
  }
  
  .courseDescriptionTextAreaField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;

    // MANUAL OVERRIDE
    .textArea {
      display: flex;
      align-self: stretch;
      height: 9.25rem;
      font-family: sans-serif;
    }
  }
  
  .classCapacityInputField {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .addCoTeacher {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .button {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

    .radioField {
      margin-top: 1.25rem;
    }
    
    .courseDescriptionTextAreaField {
      margin-top: 1.25rem;
    }
    
    .classCapacityInputField {
      margin-top: 1.25rem;
    }
    
    .addCoTeacher {
      margin-top: 1.25rem;
    }
    
  }
}
