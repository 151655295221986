.contextualMenu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem 0rem 1rem 0rem;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(222, 225, 225);
  outline-offset: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15000000596046448);
  width: 100%; // MANUAL OVERRIDE

  .contextualMenuList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
