.logEntriesCatalogByDate {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .logEntriesList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.logEntriesCatalogByStudent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .logEntriesAccordianList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
