.staffItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  background-color: rgb(255, 255, 255);

  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .image {
    display: flex;
    width: 4.75rem;
    height: 4.75rem;
  }
  
  .textContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(255, 255, 255);
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1;
    margin-left: 1rem;
  }
  
  .topText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .subText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;

  }
}
