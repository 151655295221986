.studentCourseDetailsPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  // MANUAL OVERRIDE
  .image {
    display: block;
    width: unset;
    align-self: stretch;
    height: 10rem;
  }

  // MANUAL OVERRIDE
  .logoContainer {
    display: block;
    position: absolute;
    left: 6rem;
    width: 3.5rem;
    height: 3.5rem;
    align-self: stretch;
    margin-top: 8.5rem;
    border-radius: 2rem;
    padding: 0.275rem;
    background-color: rgb(255, 255, 255);

    .logo {
      height: 3rem;
      width: 3rem;
    }
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    width: unset;
    align-self: stretch;
  }
  
  .studentCourseDetailsBlock {
    display: flex;
    width: 56.25rem;
  }
  
  @media (min-width: 1440px) {
    // MANUAL OVERRIDE
    .image {
      display: block;
      width: unset;
      align-self: stretch;
      height: 17rem;
    }
  
    // MANUAL OVERRIDE
    .logoContainer {
      display: block;
      position: absolute;
      top: 6rem;
      width: 5.5rem;
      height: 5.5rem;
      align-self: stretch;
      margin-top: 8.5rem;
      border-radius: 2.75rem;
      padding: 0.375rem;
      background-color: rgb(255, 255, 255);

      .logo {
        height: 100%;
        width: 100%;
      }
    }

    .content {
      padding: 3.75rem 0rem 3.75rem 0rem;
    }
    
    .studentCourseDetailsBlock {
      width: 78.75rem;
    }
    
  }

  @media (max-width: 1024px) {
    .studentCourseDetailsBlock {
      display: flex;
      width: 100%;
      padding: 2rem;
    }

    .logoContainer {
      left: 2rem;
    }
  }
}
