.myProfilePage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .myAccountHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    width: unset;
    align-self: stretch;
    overflow: scroll;     // MANUAL OVERRIDE
  }
  
  .myProfileBlock {
    display: flex;
    width: 56.25rem;
  }
  
  @media (min-width: 1440px) {

    .content {
      padding: 3.75rem 0rem 3.75rem 0rem;
    }
    
    .myProfileBlock {
      width: 78.75rem;
    }
    
  }
}

.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}