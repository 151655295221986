.coursesPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .coursesHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0rem 2rem 0rem;
    width: unset;
    align-self: stretch;
    overflow: scroll;     // MANUAL OVERRIDE

    // MANUAL OVERRIDE
    .title {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  
  .coursesBlock {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .iconTest {
    display: block;
    width: 4rem;
    height: 4rem;
  }
  
  .buttonText {
    display: flex;
    width: unset;
    align-self: center;
    margin-top: 1rem;
  }
}

// MANUAL OVERRIDE
.modalWrapper {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .warning {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
    background: #EFF4F4;
    border-radius: 8px;
    margin: 0.5rem 0;

    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .warningText {
    font-weight: 700;
  }

  .warningList {
    list-style: decimal;
    margin: 0.5rem 1.5rem;
  }
}

.contextualMenu { // MANUAL OVERRIDE
  width: 16.688rem;
  z-index: 1;
}
