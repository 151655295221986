.replySection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);

  .divider {
    display: block;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-color: rgb(255, 255, 255);
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .button {
    display: flex;
    margin-left: 1rem;
  }
  
}
