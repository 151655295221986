.logEntryStatusAwaitingReplyDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.logEntryStatusAwaitingReplyInverse {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.logEntryStatusRepliedDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}

.logEntryStatusRepliedInverse {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
}
