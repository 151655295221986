.toggle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.125rem;
  border-radius: 8px;
  background-color: rgb(239, 244, 244);

  .toggleItemOne {
    display: flex;
  }
  
  .toggleItemTwo {
    display: flex;
  }
  
}
