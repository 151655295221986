.radioField {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .radioItemList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
}
