.typeahead {
  width: 100%;
}

.rbt-input-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  gap: 0.5rem;
}

.rbt-input-wrapper > div:last-of-type {
  height: auto !important;
}

.rbt-input-main {
  margin: 0;
  font-size: 1rem;
  font-family: sans-serif;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  padding: 0.5rem;
  min-height: 2.25rem;
}

@media (min-width: 1440px) {
  .rbt-input-main {
    font-size: 1rem;
    min-height: 2.5rem;
  }
}
