.studentAppBlock {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 56.25rem;

  .leftContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 37rem;
  }
  
  .studentAppSection {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    flex: 1 1 auto;
    margin-left: 1.5rem;
  }
  
  @media (min-width: 1440px) {
    width: 78.75rem;

    .leftContent {
      width: 51.8125rem;
    }
    
    .rightContent {
      margin-left: 2rem;
    }
    
  }
}
