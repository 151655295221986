.cardInfoDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;

    &:hover {
      outline: 2px solid rgb(53, 120, 189);
      outline-offset: 0;
    
    }
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.625rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }
  
}

.cardInfoError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    border: 2px solid rgb(195, 63, 56);
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.625rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }

  .contextualContent {
    margin-top: 0.5rem;
  }
  
}

.cardInfoDisplay {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.625rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }
  
}

// MANUAL OVERRIDE
.cardInfoDisplayError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.75rem 0.75rem 0.75rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
    border: 2px solid rgb(195, 63, 56);
  }
  
  .icon {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .text {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.625rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.625rem;
  }

  .contextualContent {
    margin-top: 0.5rem;
  }
  
}
