.dateTimePickerFieldDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: 100%;
    align-self: stretch;
    margin-top: 0.5rem;
    > * {
      cursor: pointer;
    }
  }

  .flatPickr {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.dateTimePickerFieldError {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: 100%;
    align-self: stretch;
    margin-top: 0.5rem;
    > * {
      cursor: pointer;
    }
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }

  .flatPickr {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.dateTimePickerFieldHelp {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textInput {
    display: flex;
    width: 100%;
    align-self: stretch;
    margin-top: 0.5rem;
    > * {
      cursor: pointer;
    }
  }
  
  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .flatPickr {
    display: flex;
    width: unset;
    align-self: stretch;
  }
}
