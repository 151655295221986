.confirmationBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .image {
    display: block;
    width: 32rem;
    height: 16rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem; // MANUAL OVERRIDE
  }
  
  .textGroup1 {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .bottomContent {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
  }
  
  .appStore {
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  
  .googlePlay {
    display: block;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    margin-left: 1rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

    .image {
      width: 41rem;
      height: 20.5rem;
    }
    
  }
}
