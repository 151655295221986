.priceListItemDefault {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .descriptionText {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0rem 0.5rem 0.25rem 0.5rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .divider {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .amountText {
    display: flex;
  }
  
}

.priceListItemTotal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .descriptionText {
    display: flex;
  }
  
  .content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0rem 0.5rem 0.25rem 0.5rem;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .divider {
    display: flex;
    width: unset;
    flex: 1 1 auto;
  }
  
  .amountText {
    display: flex;
  }
  
}
