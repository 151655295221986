.teacherAgreementBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.5rem 4.5rem 1.5rem 4.5rem;
  width: 41rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .stepperText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroup {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .middleContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1.5rem;
    margin-bottom: 10rem;

    // Add styling for an ordered number list in teacher agreement
    ol {
      list-style: decimal;
      padding-left: 1.5rem;
    }

    // Add a gap between each item in the list
    li:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .textGroups {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    position: fixed;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .teacherAgreementFooter {
    display: flex;
    width: 41rem;
  }
  
  @media (min-width: 1440px) {
    padding: 3rem 4.5rem 1.5rem 4.5rem;
    width: 50rem;

    .teacherAgreementFooter {
      width: 50rem;
    }
  }
}
