.contextualMenuItemCritical {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: rgb(255, 255, 255);

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(252, 236, 238);
    outline: 2px dashed rgb(195, 63, 56);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(252, 236, 238);
  
  }
  &:pressed {
    background-color: rgb(247, 207, 211);
  
  }
}

.contextualMenuItemDefault {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.75rem 1rem 0.75rem 1rem;
  background-color: rgb(255, 255, 255);

  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
    outline-offset: 0;
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
}
