.chipContainer {
  user-select: none;
}

.chipFieldError {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: 61rem;
  }
  
  .chipInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    outline: 2px solid rgb(213, 0, 0);
    outline-offset: 0;
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  .chipList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .errorContent {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0.25rem;
  }
  
  .icon {
    display: block;
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .text {
    display: flex;
    margin-left: 0.25rem;
  }
  
  .button {
    display: flex;
    margin-left: 0.25rem;
  }
  
  &:active {
    .chipInput {
      background-color: rgb(239, 244, 244);
    }
  }


  &:focus-within {
    .chipInput {
      background-color: rgb(239, 244, 244);
      outline: 2px solid rgb(213, 0, 0);
    }

    .chipContainer {
      &:focus {
        outline: 1px dashed rgb(0, 0, 0);
      }
    }
  }


  @media (min-width: 1440px) {
    .label {
      width: unset;
      align-self: stretch;
    }
    
    .chipInput {
      padding: auto 1rem;
    }
    
    &:active {
      .chipInput {
        background-color: rgb(239, 244, 244);
      }
    }

    &:focus-visible {
      .label {
        width: 33.5rem;
      }

      .chipInput {
        background-color: rgb(239, 244, 244);
        outline: 2px solid rgb(4, 95, 216);
      }

      .errorContent {
        display: none;
        margin-top: 0;
      }
    }
  }

  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
}

.chipFieldNone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .label {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .chipInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    border-radius: 8px;
    background-color: rgb(239, 244, 244);
    width: unset;
    align-self: stretch;
    margin-top: 0.25rem;
  }

  .chipList {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .errorSpace {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    height: 1.5rem;
    align-self: stretch;
    margin-top: 0.25rem;
  }
  
  &:active {
    
    .chipInput {
      outline-offset: 0;
    }
    
  }

  &:focus-within {
    .chipInput {
      background-color: rgb(239, 244, 244);
      outline: 2px solid rgb(4, 95, 216);
    }

    .chipContainer {
      &:focus {
        outline: 1px dashed rgb(4, 95, 216);
      }
    }
  }

  &:focus-visible {
  
    .label {
      width: 61rem;
    }
    
    .chipInput {
      background-color: rgb(239, 244, 244);
      outline: 2px solid rgb(4, 95, 216);
      outline-offset: 0;
    }
    
  }
  @media (min-width: 1440px) {
    .chipInput {
      padding: auto 1rem;
    }
      
    &:active {
      .label {
        width: unset;
      }
      
      .chipInput {
        background-color: rgb(239, 244, 244);
        outline: 2px solid rgb(4, 95, 216);
        outline-offset: 0;
      }
    }
  }

  .contextualContent {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
}
