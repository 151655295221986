.labelTextGroupHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  .labelText {
    display: flex;
  }
  
  .infoText {
    display: flex;
    width: unset;
    flex: 1 1 auto;
    margin-left: 0.5rem;
  }
  
}

.labelTextGroupVertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .labelText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .infoText {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
