.iconAddSquareBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconAddSquareDisabled {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconAddSquareInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconAddSquareInverse {
  display: block;
  width: 100%;
  height: 100%;

  
  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconAddSquareSubdued {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(146, 154, 155);
  }
  
}

.iconAddSquareFilledInfo {
  display: block;
  width: 100%;
  height: 100%;

  
  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(39, 110, 241);
  }
  
}

.iconAlertFilledBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconAlertFilledCritical {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(195, 63, 56);
  }
  
}

.iconArrowLeftBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconArrowLeftDisabled {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconArrowRightBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconArrowRightInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconArrowRightInverse {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconCalendarBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconCheckboxCheckedDisabled {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconCheckboxCheckedInfo {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

   
  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconCheckboxUncheckedDisabled {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconCheckboxUncheckedInfo {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconChevronDownBigBase {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconChevronDownBigDisabled {
  display: block;
  width: 1.5rem;
  height: 1.5rem;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconChevronDownSmallBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconChevronUpBigBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconClockBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconClockFilledBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconClockFilledInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconClockFilledInverse {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}

.iconCloseBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconCloseInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconCopyBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}
//MANUAL OVERRIDE STARTS
.iconCopyInverse {
  display: block;
  width: 100%;
  height: 100%;

  path {
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}
//MANUAL OVERRIDE ENDS

.iconCoursesMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentThree], [id=ContentThree] path {
    display: block;
    fill: rgb(115, 173, 233);
  }
  
  .contentThree1 {
    display: block;
    border-radius: 0.75px;
    background-color: rgb(243, 162, 58);
    width: 100%;
    height: 100%;
  }
  
  .contentTwo {
    display: block;
    border-radius: 0.75px;
    background-color: rgb(179, 95, 154);
    width: 100%;
    height: 100%;
  }
  
  .contentOne {
    display: block;
    border-radius: 0.75px;
    background-color: rgb(124, 177, 65);
    width: 100%;
    height: 100%;
  }
  
}

.iconDotNewNew {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(93, 44, 78);
  }
  
}

.iconEditInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconEditDisabled {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconEmailBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconEmailBrand {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path {
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconEventsMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconExternalBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconExternalDisabled {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconExternalInfo {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconForumMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconHideInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconInboxMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconInfoFilledBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconInfoFilledInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconInfoFilledSubdued {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(146, 154, 155);
  }
  
}

.iconLocationBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconMenuBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconPaymentCardFilledBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconPeopleBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconQuestionBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconQuestionInfo {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconRadioSelectedBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconRadioSelectedBrand {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(87, 125, 46);
  }
  
}

.iconRadioSelectedBrandLight {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconRadioSelectedDisabled {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(200, 204, 204);
  }
  
}

.iconRadioSelectedInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconRadioSelectedMulticolor {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconRadioUnselectedBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    outline: 1.5px solid rgb(81, 94, 95);
    outline-offset: 0;
  }
  
}

.iconRadioUnselectedDisabled {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    outline: 1.5px solid rgb(200, 204, 204);
    stroke: rgb(200, 204, 204); // MANUAL OVERRIDE
    outline-offset: 0;
  }
  
}

.iconRadioUnselectedInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    outline: 1.5px solid rgb(53, 120, 189);
    outline-offset: 0;
  }
  
}

.iconResourcesMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentFour], [id=ContentFour] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentThree], [id=ContentThree] path {
    display: block;
    fill: rgb(200, 140, 183);
  }
  
  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(171, 217, 119);
  }
  
}

.iconSearchIconBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconShowInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconSupportMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconTimezoneBase {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconTrainingMulticolor {
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;

  path[id=ContentTwo], [id=ContentTwo] path {
    display: block;
    fill: rgb(179, 95, 154);
  }
  
  path[id=ContentOne], [id=ContentOne] path {
    display: block;
    fill: rgb(124, 177, 65);
  }
  
}

.iconVerticalMenuBase {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(81, 94, 95);
  }
  
}

.iconVerticalMenuInfo {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(53, 120, 189);
  }
  
}

.iconVerticalMenuInverse {
  display: block;
  width: 100%;
  height: 100%;

  path { //MANUAL OVERRIDE
    display: block;
    fill: rgb(255, 255, 255);
  }
  
}
