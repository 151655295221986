.courseCardCourse {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 12px;
  background-color: rgb(255, 255, 255);
  outline: 1px solid rgb(222, 225, 225);
  outline-offset: 0;
  height: 28.9375rem;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: unset;
    align-self: stretch;
  }
  
  .courseCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: unset;
    align-self: stretch;
  }
  
  .registrationStatusTag {
    display: flex;
  }
  
  .button {
    display: flex;
    margin-left: 16rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .bottomContent {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .classItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .topDivider {
    display: block;
    width: 23.4375rem;
    height: 0.0625rem;
    margin-top: 1rem;
  }
  
  .progressContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
  .iconTextGroup {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .bottomDivider {
    display: block;
    width: 23.4375rem;
    height: 0.0625rem;
    margin-top: 1rem;
  }
  
  .button1 {
    display: flex;
    margin-top: 1rem;
  }
  
  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  @media (min-width: 1440px) {
    height: 32.4375rem;

  &:focus-visible {
    background-color: rgb(240, 247, 255);
    outline: 2px dashed rgb(53, 120, 189);
  
  }
  &:hover {
    background-color: rgb(240, 247, 255);
  
  }
  &:pressed {
    background-color: rgb(216, 235, 255);
  
  }
  }
}

.courseCardCreateNew {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 28.9375rem;

  .icon {
    display: block;
    width: 4rem;
    height: 4rem;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: center;
    margin-top: 1rem;
  }

  .button2 {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    background-color: rgb(240, 247, 255);
    border: 2px dashed rgb(53, 120, 189);

    > * {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
    
    &:active {
      background-color: rgb(216, 235, 255);
      border: 2px dashed rgb(53, 120, 189);
    }

    &:focus-visible {
      background-color: rgb(216, 235, 255);
      border: 2px solid rgb(53, 120, 189);
      
    }
  }

  @media (min-width: 1440px) {
    height: 32.4375rem;
  }
}
