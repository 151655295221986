.bodyFooterLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .studentCourseDetailsPage {
    display: flex;
    width: unset;
    height: unset;
    align-self: stretch;
    flex: 1 1 auto;
  }
  
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .studentCourseDetailsFooter {
    display: flex;
    width: unset;
    align-self: stretch;
  }

  .inlineNotification {
    display: flex;
    position: absolute;
    align-self: stretch;
    align-self: center;
    width: 100%;

    div {
      margin: auto;
    }
  }
  
}
