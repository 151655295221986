.dividerHorizontalDottedBorderBase {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px dashed #DEE1E1;

  .divider {
    display: block;
    outline: 1px dashed rgb(222, 225, 225);
    outline-offset: 0;
  }
  
}

.dividerHorizontalSolidBorderBase {
  display: block;
  background-color: rgb(222, 225, 225);
  width: 23.4375rem;
  height: 0.0625rem;

}

.dividerHorizontalSolidBrand {
  display: block;
  background-color: rgb(124, 177, 65);
  width: 23.4375rem;
  height: 0.25rem;

}

.dividerHorizontalSolidCritical {
  display: block;
  background-color: rgb(195, 63, 56);
  width: 23.4375rem;
  height: 0.25rem;

}

.dividerHorizontalSolidInfo {
  display: block;
  background-color: rgb(53, 120, 189);
  width: 23.4375rem;
  height: 0.25rem;

}

.dividerVerticalSolidBorderBase {
  display: block;
  background-color: rgb(222, 225, 225);
  width: 0.0625rem;
  height: 100%;

}
