.classItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .topContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: unset;
    align-self: stretch;
  }
  
  .text {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .divider {
    display: block;
    width: unset;
    align-self: stretch;
    margin-top: 0.5rem;
  }
  
  .iconTextGroupList {
    display: flex;
    width: unset;
    align-self: stretch;
    margin-top: 1rem;
  }
  
}
