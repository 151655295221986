.navMenuListCollapsed {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  gap: 0.25rem;

  .navMenuItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}

.navMenuListExpanded {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(255, 255, 255);
  gap: 0.25rem;

  .navMenuItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  @media (min-width: 1440px) {
    width: 87.5rem;

  }
}
