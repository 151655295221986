.signinPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 720px) {
    width: 100%;
  }

  .signinBlock {
    display: flex;
    width: 41rem;

    @media screen and (max-width: 720px) {
      padding: 1.5rem 0.5rem;
      width: 100%;
    }
  }
  
  @media (min-width: 1440px) {

    .signinBlock {
      width: 50rem;

      @media screen and (max-width: 720px) {
        padding: 1.5rem 0.5rem;
        width: 100%;
      }
    }
  }
}
