.logbookPage {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .courseHeader {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: unset;
    align-self: stretch;
    flex: 1 1 auto;
    height: 100%;
  }
  
  .logbookBlock {
    display: flex;
    align-self: stretch;
    flex: 1 1 auto;
    //  total height - (main header + course header)
    height: calc(100vh - 12.75rem);

    @media (min-width: 1440px) {
       // total height - (main header + course header)
      height: calc(100vh - 14.75rem);
    }
  }
}
