.radioItemListHorizontal {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .radioItem {
    display: flex;
    width: calc(33.333333333333336% - 0.6666666666666666rem);
  }
  
}

.radioItemListVertical {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;

  .radioItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
