.contextualMenuList {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%; // MANUAL OVERRIDE

  .contextualMenuItem {
    display: flex;
    width: unset;
    align-self: stretch;
  }
  
}
